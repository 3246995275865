import React, { useEffect, useState, memo } from 'react';
import PropTypes from 'prop-types';
import { isMobile, isMobileOnly, isTablet } from 'mobile-device-detect';
import { withRouter } from 'react-router-dom';
import { Card, Row, Col } from 'reactstrap';
import axios from 'axios';
import List from '@material-ui/core/List';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Avatar from '@material-ui/core/Avatar';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItem from '@material-ui/core/ListItem';
import CustomScroll from 'react-custom-scrollbars';
import Placeholder from '../../../config/placeholder.config';
import { config } from '../../settings';
import Tabs from '../../components/tabs-standard';
import PageWrap from '../../components/page-wrap';
import FeedList from '../FeedList';
import PageMeta from '../../components/PageMeta';

let x = 0;
function Feed({ history }) {
  const [activeTab, setActiveTab] = useState('cigars');
  const [trendingCigars, setTrendingCigars] = useState([]);
  const [loadingRecent, setLoadingRecent] = useState(true);

  useEffect(() => {
    // jQuery('body').addClass('no-scroll');
    if (!isMobileOnly) {
      axios.get(`${config.apiEndPoint}/scans/recent`).then((scans) => {
        console.log('Got scans');
        console.log(scans);
        setTrendingCigars(scans.data);
        setLoadingRecent(false);
      }).catch((err) => {
        console.log(err);
        setLoadingRecent(false);
      });
    }
    return () => {
      // jQuery('body').removeClass('no-scroll');
    };
  }, []);

  const toggleTab = (name) => {
    setActiveTab(name);
  };

  // FIXME This is part of the cigar model - use it here
  const getImage = (scan) => {
    let imageUrl = Placeholder.cigar;

    if (scan.image_url) {
      imageUrl = scan.image_url.replace('/cigars/', '/100x100/cigars/');
    }

    if (scan.sessions && scan.sessions.length > 0 && scan.sessions[0].image_url) {
      imageUrl = scan.sessions[0].image_url.replace('/sessions/', '/100x100/sessions/');
    }

    if (scan.sessions && scan.sessions.length > 0 && scan.sessions[0].media && scan.sessions[0].media.length > 0) {
      for (const media of scan.sessions[0].media) {
        if (media.media_type === 'image') {
          imageUrl = media.media_url.replace('/sessions/', '/100x100/sessions/');
          break;
        }
      }
    }

    return imageUrl;
  };

  const renderSideTabs = () => (
    <div style={{ width: isTablet ? '100%' : 300, display: 'block', position: 'absolute', top: isTablet ? 0 : 64, right: window.innerWidth < 1024 ? 0 : 30, height: `calc(100vh - ${isTablet ? 125 : 64}px)` }}>
      <CustomScroll autoHide heightRelativeToParent="100%">
        <div style={{ padding: 24, marginTop: isTablet ? 0 : 20, marginBottom: 30 }}>
          {/* <h4>Sponsored</h4> */}
          {/* <div> */}
          {/*  /!* TODO These should be our internal ads shown from businesses *!/ */}
          {/*  <a href="https://www.dpbolvw.net/click-7834272-11660830" target="_blank"> */}
          {/*    <img style={{ borderRadius: 4 }} src="https://www.tqlkg.com/image-7834272-11660830" width="100%" alt="Save up to 40% on major retail brands for cigarillos, little cigars and filtered cigars." border="0" /> */}
          {/*  </a> */}
          {/* </div> */}
          {/* <h4 style={{ marginTop: 20 }}>Your Groups</h4> */}
          {/* <Card style={{ padding: 16 }}> */}
          {/*  Coming soon */}
          {/* </Card> */}
          {/* <h4 style={{ marginTop: 20 }}>Events</h4> */}
          {/* <Card> */}
          {/*  <div style={{ paddingTop: 10 }}> */}
          {/*    <span style={{ marginLeft: 16 }}>Upcoming</span> */}
          {/*    <List dense> */}
          {/*      {events.map((event) => ( */}
          {/*        <ListItem onClick={() => history.push(`/events/${hashids.encode(event.id)}`)} key={event.id} button> */}
          {/*          <ListItemAvatar> */}
          {/*            <Avatar */}
          {/*              alt={event.name} */}
          {/*              src={event.image_url} */}
          {/*            > */}
          {/*              {event.name.charAt(0)} */}
          {/*            </Avatar> */}
          {/*          </ListItemAvatar> */}
          {/*          <span></span> */}
          {/*          <ListItemText */}
          {/*            id={hashids.encode(event.id)} */}
          {/*            primary={renderEventTimestamp(event.start_timestamp)} */}
          {/*            secondary={ */}
          {/*              <React.Fragment> */}
          {/*                <div style={{ fontSize: '1.1rem' }}>{event.name}</div> */}
          {/*                <Link to={`/venues/${hashids.encode(event.venue.id)}`}>{`${event.venue.name} • ${event.venue.formatted_address}`}</Link> */}
          {/*              </React.Fragment> */}
          {/*            } */}
          {/*          /> */}
          {/*          <ListItemSecondaryAction> */}
          {/*            /!* TODO Button to attend? *!/ */}
          {/*          </ListItemSecondaryAction> */}
          {/*        </ListItem> */}
          {/*      ))} */}
          {/*      <div style={{ textAlign: 'center' }}> */}
          {/*        <Button */}
          {/*          outline */}
          {/*          onClick={() => props.history.push('/events')} */}
          {/*        > */}
          {/*          {'View All Events'} */}
          {/*        </Button> */}
          {/*      </div> */}
          {/*    </List> */}
          {/*  </div> */}
          {/* </Card> */}
          <h4 style={{ marginTop: 20 }}>Trending</h4>
          <Card>
            <div style={{ paddingTop: 10 }}>
              <Tabs sliding>
                <Tabs.NavItem
                  isActive={activeTab === 'cigars'}
                  onClick={() => toggleTab('cigars')}
                >
                  {'Cigars'}
                </Tabs.NavItem>
                <Tabs.NavItem
                  isActive={activeTab === 'venues'}
                  onClick={() => toggleTab('venues')}
                >
                  {'Venues'}
                </Tabs.NavItem>
              </Tabs>
            </div>
            <Tabs.Content activeTab={activeTab}>
              <Tabs.Pane tabId="cigars">
                <List dense>
                  {trendingCigars && trendingCigars.sort((a, b) => b.cigar.avg_rating - a.cigar.avg_rating).map((scan) => (
                    <ListItem
                      onClick={() => {
                        // FIXME This should never happen - the server should never send one without a cigar, not sure why it currently is
                        if (scan.cigar) {
                          history.push(`/cigars/${scan.cigar.hash_id}`);
                        }
                      }}
                      key={scan.id || scan.scan_id}
                      button
                    >
                      <ListItemAvatar>
                        <Avatar
                          alt={scan.cigar.full_name}
                          src={getImage(scan)}
                        >
                          {scan.cigar.full_name.charAt(0)}
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText id={scan.cigar.hash_id} primary={scan.cigar.full_name} />
                      <ListItemSecondaryAction>
                        <span>{scan.cigar.avg_rating}</span>
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))}
                </List>
                {!loadingRecent && (!trendingCigars || trendingCigars.length === 0) && <div style={{ padding: 16, textAlign: 'center' }}>Unable to load trending cigars</div>}
              </Tabs.Pane>
              <Tabs.Pane tabId="venues">
                <div style={{ padding: 16, textAlign: 'center' }}>Not enough data yet</div>
              </Tabs.Pane>
            </Tabs.Content>
          </Card>
        </div>
      </CustomScroll>
    </div>
  );
  console.log(`Rendered Feed Wrapper ${++x} times`)
  if (isMobileOnly) {
    return (
      <PageWrap>
        <PageMeta>
          <div>
            <FeedList />
          </div>
        </PageMeta>
      </PageWrap>
    );
  }
  if (isTablet) {
    return (
      <div>
        <PageMeta>
          <Row style={{ margin: isMobile ? 0 : 'inherit' }}>
            <Col md={8}>
              <FeedList />
            </Col>
            <Col md={4}>
              {renderSideTabs()}
            </Col>
          </Row>
        </PageMeta>
      </div>
    );
  }
  return (
    <div>
      <PageMeta>
        <FeedList />
        {renderSideTabs()}
      </PageMeta>
    </div>
  );
}

Feed.propTypes = {
  location: PropTypes.object.isRequired,
};

export default memo(withRouter(Feed));
