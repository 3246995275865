import { fromJS, List } from 'immutable';
import { LOAD_FEED, ADD_OR_UPDATE_FEED_ITEM } from './actions';

const listState = fromJS({
  currentPage: 0,
  totalPages: 999,
  loading: false,
  errorMessage: '',
  posts: List(), // INFO List works better than set for objects vs IDs
});
export function listReducer(state = listState, action) {
  switch (action.type) {
    case LOAD_FEED.FETCH: {
      return state
        .set('currentPage', action.page)
        .set('loading', true);
    }

    case LOAD_FEED.SUCCESS: {
      const { paging, posts, page } = action;
      console.log(paging);
      console.log('Page:');
      console.log(page);
      if (page === 1) {
        return state
          .set('totalPages', parseInt(paging.totalPages, 10))
          .set('loading', false)
          .set('posts', fromJS(posts));
      }
      return state
        .set('totalPages', parseInt(paging.totalPages, 10))
        .set('loading', false)
        // FIXME This should really be the Post IDs (except they don't currently work that way) so we
        //  can have the list of posts saved in cache and when routing to that page, they can just be
        //  pulled from cache by ID if available or default back to fetching from the server (like it's
        //  doing now). Should minimize calls to the server and speed things up
        .update('posts', (list) => list.push(...posts));
    }

    case LOAD_FEED.FAILURE: {
      return state
        .set('loading', false)
        .set('errorMessage', action.error.message);
    }

    case LOAD_FEED.CANCEL: {
      return state.set('loading', false);
    }

    case ADD_OR_UPDATE_FEED_ITEM: {
      // TODO Not ideal to iterate to find a match based on the type, but should work for now compared to pulling it
      //   from the server again - check for existing item and insert on top (or where it belongs in the feed)
      const items = state.get('posts').toJS();
      console.log('Items before:');
      console.log(items);
      let updated = false;
      for (let i = items.length - 1; i >= 0; i--) {
        let item = items[i];
        if (item.id === action.item.id) {
          item = action.item;
          updated = true;
        }
      }
      if (!updated) {
        items.unshift(action.item);
      }
      console.log('Items after:');
      console.log(items);
      return state.set('posts', items);
    }

    default:
      return state;
  }
}

export default function listMappingReducer(state = listState, action) {
  switch (action.type) {
    case LOAD_FEED.FETCH:
    case LOAD_FEED.SUCCESS:
    case LOAD_FEED.FAILURE:
    case ADD_OR_UPDATE_FEED_ITEM: {
      console.log('Updating feed items in reducer...');
      const selectedList = state.get('feed_items');
      const updatedList = listReducer(selectedList, action);
      return state.set('feed_items', updatedList);
    }

    default:
      return state;
  }
}
