import { createAsyncAction } from '../../utils/actionUtils';
import { FeedStore } from '../../stores';

export const LOAD_FEED = createAsyncAction('LOAD_FEED');
export const ADD_OR_UPDATE_FEED_ITEM = 'ADD_OR_UPDATE_FEED_ITEM';

export function requestFeed(page, limit = 50) {
  console.log('Getting feed...');
  return {
    type: LOAD_FEED.REQUEST,
    page,
    limit,
  };
}

export function loadFeed(page, limit = 50) {
  return {
    type: LOAD_FEED.FETCH,
    page,
    limit,
  };
}

export function loadFeedSuccess(page, paging, posts) {
  FeedStore.update((s) => {
    if (page === 1) {
      s.feed = posts;
    } else {
      s.feed.push(...posts);
    }
    window.localStorage.setItem('boxpressd-main-feed', JSON.stringify(s.feed));
  });
  return {
    type: LOAD_FEED.SUCCESS,
    page,
    paging,
    posts,
  };
}

export function loadFeedFail(error) {
  return {
    type: LOAD_FEED.FAILURE,
    error,
  };
}

/**
 * Used to insert or update items such as smoke sessions or venue checkins in the feed list. The item must include the
 * `id` and the `type` so it can be correctly searched for.
 * @param item
 * @returns {{item: *, type: string}}
 */
export function addOrUpdateFeedItem(item) {
  return {
    type: ADD_OR_UPDATE_FEED_ITEM,
    item,
  };
}
