import { put, select, takeLatest } from 'redux-saga/effects';
import axios from 'axios';
import { EXPLORE_CIGARS, exploreCigars, exploreCigarsSuccess, exploreCigarsError } from './actions';

const env = process.env.NODE_ENV || 'development';
const config = require('../../../config/config.json')[env];

function* explore(action) {
  const { options, page } = action;

  // const isLoading = yield select(selectCigarsLoading());
  // if (isLoading) {
  //   return;
  // }

  yield put(exploreCigars(page, options));

  const limit = 30;
  let query = `limit=${limit}&page=${page || 1}`;

  if (options.origin) {
    query += `&origin=${options.origin}`;
  }

  if (options.wrapper) {
    query += `&wrapper=${options.wrapper}`;
  }

  if (options.strength) {
    query += `&strength=${options.strength}`;
  }

  if (options.price_min) {
    query += `&price_min=${options.price_min}`;
  }

  if (options.price_max) {
    query += `&price_max=${options.price_max}`;
  }

  if (options.type) {
    query += `&package_type=${options.type}`;
  }

  if (options.sort) {
    query += `&sort=${options.sort}`;
  }

  // TODO Add sort order to the filter options
  console.log(`Fetching ${config.apiEndPoint}/cigars/explore?${query}`);

  const result = yield axios.get(`${config.apiEndPoint}/cigars/explore?${query}`);

  console.log('Explorer result');
  console.log(result);

  if (result.status === 200) {
    // console.log('Cigars:');
    // console.log(result.data);
    yield put(exploreCigarsSuccess({
      // FIXME Can this be returned by the server? Just have a high number until returned results length == 0, then set to current page #?
      currentPage: page,
      totalPages: 10,
      hasMore: result.data.length > 0 && result.data.length === limit,
    },
    result.data));
  } else {
    yield put(exploreCigarsError({
      success: false,
      message: 'Unable to get cigars',
    }));
  }
}

export function* exploreCigarsWatcher() {
  yield takeLatest(EXPLORE_CIGARS.REQUEST, explore);
}

export default [
  exploreCigarsWatcher,
];
