import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { isMobile, isMobileOnly } from 'mobile-device-detect';
import Chip from '@material-ui/core/Chip';
import { Button } from '@material-ui/core';
import Hashids from 'hashids/cjs';
import { Virtuoso } from 'react-virtuoso';
import CardList from '../../components/CardList';
import { requestFeed, watchFeed } from './helper';
import { selectFeedErrorMessage, selectFeedHasMore } from './selectors';
import FeedSessionCard from '../../components/FeedSessionCard';
import CardListSkeleton from '../../components/CardList/skeleton';
import FeedCheckinCard from '../../components/FeedCheckinCard';
import FeedScanCard from '../../components/FeedScanCard';
import GroupPostCardList from '../../components/FeedGroupPostCardList';
import FeedBusinessPostCard from '../../components/FeedBusinessPostCardList';
import FeedEventCard from '../../components/FeedEventCard';
import Icon from '../../components/Icon';
import { setSearchAction as actionSetSearchAction, updateSettings as actionUpdateSettings } from '../../actions';
import FeedProductReviewCard from '../../components/FeedProductReviewCard';
import HumidorEditor from '../HumidorEditor';
import { selectAllHumidors } from '../Humidor/selectors';
import { FeedStore } from '../../stores';
import PodcastEpisodeCard from '../../components/FeedPodcastEpisodeCard';
import { redirectAuth } from '../../utils/redirect';
import { Transducer } from '../../utils/transducer';
import safeAreaInsets from 'safe-area-insets';
import ProductCardList from '../../components/ProductCardList';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Snackbar from '@material-ui/core/Snackbar';
import DynamicAd from '../../components/dynamic-ad';

const hashids = new Hashids('', 12);
const { RateApp } = window.Capacitor.Plugins;

let scrollOffset = 0;
let lastScrollOffset = 0;
let range = { startIndex: 0, stopIndex: 50 };

let x = 0;

// FIXME Should use https://react-window-next.now.sh/#/examples/list/dynamic-size
function FeedList({ hasMore, auth, settings, history, updateSettings, setSearchAction }) {
  const posts = FeedStore.useState((s) => {
    if (!isMobileOnly) {
      return [{ type: 'quick_actions' }, ...s.feed];
    }
    console.log('Current feed items:');
    console.log(s.feed);
    return s.feed;
  });
  const currentPage = FeedStore.useState((s) => s.currentPage);
  const hasNewPosts = FeedStore.useState((s) => s.hasNewPosts);
  const [shouldShowRatingPrompt, setShouldShowRatingPrompt] = useState(localStorage.getItem('shouldShowRatingPrompt') === 'true');
  const [quickActions, setQuickActions] = useState([]);
  const [showHumidorEditor, setShowHumidorEditor] = useState(false);
  const [showRatingConfirm, setShowRatingConfirm] = useState(false);
  const [showRatingReject, setShowRatingReject] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const virtuoso = React.useRef(null);

  const user = auth && auth.user;

  const scrollToTop = () => {
    if (virtuoso && virtuoso.current) {
      virtuoso.current.scrollToIndex({ index: 0, align: 'start', behavior: lastScrollOffset > 10000 ? 'auto' : 'smooth' });
    }
  };

  useEffect(() => {
    // FIXME This is getting called when we come back - that is probably why it won't retain the position
    console.log('User in feed:');
    console.log(user);

    console.log('Getting initial page...');
    requestFeed(1);
    // watchFeed();

    window.addEventListener('scrollFeedToTop', scrollToTop);
    window.addEventListener('statusTap', scrollToTop);
    return () => {
      window.removeEventListener('scrollFeedToTop', scrollToTop);
      window.removeEventListener('statusTap', scrollToTop);
    };
  }, []);

  useEffect(() => {
    const nav = settings.navigation;
    if (isMobile) {
      Object.keys(nav).forEach((url) => {
        const data = nav[url];
        if (data.chips) {
          setQuickActions(data.chips);
        }
      });
    }
    return () => {
      jQuery('.plus-to-x').parent().show();
      jQuery('.rui-navbar-mobile').css('position', 'fixed');
    };
  }, [quickActions]);

  // useEffect(() => {
  //   console.log('Updated feed items:');
  //   console.log(posts);
  //   setAllPosts(posts);
  //   // console.log('Last scroll offset (feed items):');
  //   // console.log(scrollOffset);
  // }, [posts]);
  //
  // useEffect(() => {
  //   const { humidors } = props;
  //
  //   console.log('Humidors in feed:');
  //   console.log(humidors);
  //
  //   const topCards = []; // [{ type: 'quick_actions' }];
  //   if (settings.show_create_humidor_card) {
  //     if (!user || !humidors || humidors.length === 0) {
  //       topCards.push({ type: 'create_humidor' });
  //     }
  //   }
  //   setAllPosts(topCards.concat(posts));
  // }, [humidors, settings.show_create_humidor_card]);

  useEffect(() => {
    console.log(shouldShowRatingPrompt ? 'Prompting' : 'Not prompting');
    if (shouldShowRatingPrompt && posts.length > 0) {
      let showingRatingPrompt = false;
      // INFO It'll only be in the top few slots
      posts.slice(0, 5).forEach((post) => {
        if (post.type === 'rate_app') {
          showingRatingPrompt = true;
        }
      });
      if (!showingRatingPrompt) {
        FeedStore.update((s) => {
          s.feed.unshift({ type: 'rate_app' });
        });
      }
    } else if (posts.length > 0) {
      if (posts[0].type === 'rate_app') {
        console.log('Removing rating card...');
        FeedStore.update((s) => {
          s.feed.shift();
        });
      }
    }
    console.log('Feed items:');
    console.log(posts);
  }, [posts, shouldShowRatingPrompt]);

  useEffect(() => {
    if (window.location.pathname === '/' && scrollOffset > 100) {
      setShowSnackbar(hasNewPosts);
    }
  }, [hasNewPosts]);

  // useEffect(() => {
  //   virtuoso.current.scrollToIndex({ index: 0, align: 'center', behavior: 'auto' });
  // }, []);

  const fetchFeed = (startIndex, stopIndex) => {
    // console.log('Fetching new data...');
    // console.log(`From ${startIndex} to ${stopIndex}`);
    // console.log('Length: ', posts.length - 10);
    if (hasMore && stopIndex > posts.length - 10) { // TODO Probably want a slightly bigger threshold?
      // console.log(`Fetching page ${currentPage + 1}`);
      requestFeed(currentPage + 1);
    }
  };

  const renderHumidorEditor = () => (
    <HumidorEditor
      show={showHumidorEditor}
      onClose={(humidor) => {
        if (humidor) {
          Transducer.setPreference('show_create_humidor_card', 'false');
          updateSettings({
            show_create_humidor_card: 'false',
          });
          history.push(`/profile/humidors?id=${hashids.encode(humidor.id)}`);
        }
        setShowHumidorEditor(false);
      }}
    />
  );

  const renderQuickActions = () => {
    const nav = settings.navigation;
    let quickActions = [];
    Object.keys(nav).forEach((url) => {
      const data = nav[url];
      if (data.chips) {
        quickActions = data.chips;
      }
    });
    return (
      <div
        // ref={this.quickActionsDropdown}
        style={{
          height: 44,
          overflow: 'hidden', // Right now, I don't think we need it and it shows a scroll bar on webkit browsers
          overflowScrolling: 'touch',
          whiteSpace: 'nowrap',
          maxWidth: 608,
          margin: 'auto',
          paddingLeft: 10,
          paddingRight: 10,
        }}
      >
        { quickActions.map((item) => (
          <div style={{ margin: 5, display: 'inline-block' }}>
            <a href={item.path} target="_blank" rel="noreferrer">
              <Chip
                icon={<Icon name={item.icon} />}
                color="default"
                label={item.name}
                onClick={() => item.action(() => {
                  jQuery('#more-to-x-checkbox').prop('checked', false);
                  jQuery('#mobile-search-input').focus();

                  console.log('Clicked Chip:');
                  console.log(item);
                  window.dispatchEvent(new CustomEvent('showSearch', {
                    detail: {
                      action: {
                        route: item.route,
                        params: item.params,
                        results: item.results,
                        replace: false,
                      },
                    },
                  }));
                })}
              />
            </a>
          </div>
        ))}
      </div>
    );
  };

  const Item = ({ post }) => {
    if (!post) {
      return <CardListSkeleton />;
    }
    if (post.type === 'ad') {
      return (
        <div className="feed-card-item">
          <DynamicAd
            zoneId={post.zone_id}
            onResolve={(ad) => {
              if (ad) {
                // TODO Replace it in the feed store array - it should "cache" the ad data so it can just be served when
                //  scrolling through the feed without hitting AdServer again for a new one
              }
            }}
          />
        </div>
      );
    }
    if (post.type === 'quick_actions') {
      return (
        <div>
          <p style={{ color: settings.night_mode ? '#efefef' : '#121212', maxWidth: 608, margin: 'auto' }}>Quick Actions</p>
          <div style={{ height: 56 }}>
            {renderQuickActions()}
          </div>
        </div>
      );
    }
    if (post.type === 'create_humidor') {
      return (
        <div>
          <div className="feed-card-item">
            <div className="content" style={{ padding: 16 }}>
              <div style={{ display: 'flex', marginBottom: 12 }}>
                <div style={{ flex: 1 }}>
                  <h3>Create your first virtual humidor</h3>
                  <div>Start keeping track of your inventory</div>
                </div>
                <img src="http://cdn.boxpressd.io/assets/img/icons/virtual-humidor.png" style={{ width: 75, height: 75, marginLeft: 10, marginTop: 10 }} />
              </div>
              <span style={{ float: 'right' }}>
                <Button
                  color="secondary"
                  onClick={() => {
                    Transducer.setPreference('show_create_humidor_card', 'false');
                    updateSettings({
                      show_create_humidor_card: 'false',
                    });
                  }}
                >
                  {'Dismiss'}
                </Button>
                <Button
                  color="secondary"
                  onClick={() => {
                    if (user) {
                      setShowHumidorEditor(true);
                    } else {
                      redirectAuth(`${window.location.href}?action=create_humidor`, false);
                    }
                  }}
                >
                  {'Create Humidor'}
                </Button>
              </span>
              <div className="clearfix" />
            </div>
          </div>
        </div>
      );
    }
    if (post.type === 'rate_app') {
      return (
        <div>
          <div className="feed-card-item">
            <div className="content" style={{ padding: 16, display: (showRatingConfirm || showRatingReject) ? 'none' : 'block' }}>
              <div style={{ display: 'flex', marginBottom: 12 }}>
                <div style={{ flex: 1 }}>
                  <h3>Are you enjoying Boxpressd?</h3>
                </div>
              </div>
              <span style={{ float: 'right' }}>
                <Button
                  color="primary"
                  variant="outline"
                  onClick={() => setShowRatingReject(true)}
                  style={{ opacity: 0.5 }}
                >
                  {'No'}
                </Button>
                <Button
                  color="secondary"
                  onClick={() => setShowRatingConfirm(true)}
                >
                  <span style={{ color: '#007bff', fontWeight: 600 }}>{'Yes'}</span>
                </Button>
              </span>
              <div className="clearfix" />
            </div>
            <div className="content" style={{ padding: 16, display: showRatingConfirm ? 'block' : 'none' }}>
              <div style={{ display: 'flex', marginBottom: 12 }}>
                <div style={{ flex: 1 }}>
                  <h3>Would you like to rate us on {window.Capacitor.isNative ? (window.Capacitor.platform == 'ios' ? 'Apple App Store' : 'Google Play Store') : 'Trustpilot'}?</h3>
                </div>
              </div>
              <span style={{ float: 'right' }}>
                <Button
                  color="primary"
                  variant="outline"
                  onClick={() => {
                    // TODO Set date in the future to ask for a rating again
                    localStorage.setItem('shouldShowRatingPrompt', 'false');
                    setShouldShowRatingPrompt(false);
                  }}
                >
                  {'Not now'}
                </Button>
                <Button
                  color="secondary"
                  onClick={() => {
                    localStorage.setItem('shouldShowRatingPrompt', 'false');
                    setShouldShowRatingPrompt(false);
                    if (window.Capacitor.isNative) {
                      // FIXME If the user left a review already, this won't do anything and it's not guaranteed to show
                      //  anyway - might be better off just linking to the app store listings like in settings
                      RateApp.requestReview();
                    } else {
                      window.open('https://www.trustpilot.com/evaluate/boxpressd.com');
                    }
                  }}
                >
                  <span style={{ color: '#007bff', fontWeight: 600 }}>{'Yes'}</span>
                </Button>
              </span>
              <div className="clearfix" />
            </div>
            <div className="content" style={{ padding: 16, display: showRatingReject ? 'block' : 'none' }}>
              <div style={{ display: 'flex', marginBottom: 12 }}>
                <div style={{ flex: 1 }}>
                  <h3>We're sorry to hear that. Would you like to leave feedback?</h3>
                </div>
              </div>
              <span style={{ float: 'right' }}>
                <Button
                  color="primary"
                  variant="outline"
                  onClick={() => {
                    localStorage.setItem('shouldShowRatingPrompt', 'false');
                    setShouldShowRatingPrompt(false);
                  }}
                >
                  {'No thanks'}
                </Button>
                <Button
                  color="secondary"
                  onClick={() => {
                    localStorage.setItem('shouldShowRatingPrompt', 'false');
                    setShouldShowRatingPrompt(false);
                    // document.querySelector('#fc_frame').style.display = 'block';
                    // if (window.fcWidget) {
                    //   window.fcWidget.open({ name: 'Feedback' });
                    // }
                    if (window.tidioChatApi) {
                      window.tidioChatApi.show();
                      window.tidioChatApi.open();
                    }
                  }}
                >
                  <span style={{ color: '#007bff', fontWeight: 600 }}>{'Yes'}</span>
                </Button>
              </span>
              <div className="clearfix" />
            </div>
          </div>
        </div>
      );
    }
    let businessId;
    let businessType;
    if (post.blog_article) {
      const { venue, brand } = post.blog_article;
      const item = venue || brand || {};
      if (item) {
        businessId = item.id;
      }
      businessType = post.blog_article.business_type;
    } else if (post.event) {
      const { venue, brand } = post.event;
      const item = venue || brand || {};
      if (item) {
        businessId = item.id;
      }
      businessType = post.event.business_type;
    } else if (post.business_post) {
      const { venue, brand } = post.business_post;
      const item = venue || brand || {};
      if (item) {
        businessId = item.id;
      }
      businessType = post.business_post.business_type;
    } else if (post.podcast_episode) {
      const { venue, brand } = post.podcast_episode;
      const item = venue || brand || {};
      if (item) {
        businessId = item.id;
      }
      businessType = post.podcast_episode.business_type;
    }
    window.analytics?.track('Feed Item Impressions', {
      feed_item_id: post.id,
      post_id: post.item_id,
      post_type: post.type,
      business_id: businessId,
      business_type: businessType,
      user_id: auth && auth.user && auth.user.id,
    });
    if (post.type === 'session' && post.session) {
      return (
        <div>
          <FeedSessionCard
            key={post.session_id}
            session={post.session}
            likeCount={post.like_count || 0}
            commentCount={post.comment_count || 0}
            showTitle
            avgRating
          />
        </div>
      );
    }
    if (post.type === 'checkin' && post.checkin) {
      return (
        <div>
          <FeedCheckinCard
            key={post.checkin_id}
            checkin={post.checkin}
            likeCount={post.like_count || 0}
            commentCount={post.comment_count || 0}
            showTitle
            avgRating
          />
        </div>
      );
    }
    if (post.type === 'product' && post.product) {
      return (
        <ProductCardList
          key={post.product_id}
          item={post.product}
          orientation="vertical"
        />
      );
    }
    if (post.type === 'product_review' && post.product_review) {
      return (
        <div>
          <FeedProductReviewCard
            key={post.product_review_id}
            review={post.product_review}
            likeCount={post.like_count || 0}
            commentCount={post.comment_count || 0}
            showTitle
            avgRating
          />
        </div>
      );
    }
    if (post.type === 'scan') {
      if (post.scan) { // Not sure why these can be null in dev - I think I deleted them to clear up room in the db
        return (
          <div>
            <FeedScanCard
              key={post.scan_id}
              scan={post.scan}
              likeCount={post.like_count || 0}
              commentCount={post.comment_count || 0}
              showTitle
              avgRating
            />
          </div>
        );
      }
    }
    if (post.type === 'group_post' && post.group_post) {
      console.log(post);
      console.log(post.group_post);
      return (
        <div>
          <GroupPostCardList
            key={post.group_post_id}
            post={post.group_post}
            likeCount={post.like_count || 0}
            commentCount={post.comment_count || 0}
            showTitle
          />
        </div>
      );
    }
    if (post.type === 'event' && post.event) {
      return (
        <div>
          <FeedEventCard
            key={post.event_id}
            event={post.event}
            timestamp={post.timestamp}
            likeCount={post.like_count || 0}
            commentCount={post.comment_count || 0}
            showTitle
            compact
          />
        </div>
      );
    }
    if (post.type === 'business_post' && post.business_post) {
      return (
        <div>
          <FeedBusinessPostCard
            key={post.business_post_id}
            post={post.business_post}
            likeCount={post.like_count || 0}
            commentCount={post.comment_count || 0}
            showTitle
          />
        </div>
      );
    }
    if (post.type === 'article') {
      return (
        <div>
          <CardList
            key={post.id}
            item={post}
          />
        </div>
      );
    }
    if (post.type === 'blog_article' && post.blog_article) {
      return (
        <div>
          <CardList
            key={post.blog_article_id}
            item={post.blog_article}
            sponsored={post.current_budget}
          />
        </div>
      );
    }
    if (post.type === 'podcast_episode' && post.podcast_episode) {
      return (
        <div>
          <PodcastEpisodeCard episode={post.podcast_episode} post={post} />
        </div>
      );
    }
    return <div style={{ height: 1 }} />;
    return (
      <div style={{ maxWidth: 608, margin: 'auto', padding: 20, border: '1px solid #aaaaaa' }}>
        <p>Unable to render card for {post.type}.</p>
        {/*<p>{JSON.stringify(post).slice(0, 350)}</p>*/}
      </div>
    );
  };

  return (
    <div>
      {isMobileOnly && (
        <div id="quick-action-chips">
          { quickActions.map((item) => (
            <div style={{ margin: 5, display: 'inline-block' }}>
              <a href={item.path} target="_blank" rel="noreferrer">
                <Chip
                  icon={<Icon name={item.icon} />}
                  color="default"
                  label={item.name}
                  onClick={() => item.action(() => {
                    jQuery('#more-to-x-checkbox').prop('checked', false);
                    jQuery('#mobile-search-input').focus();

                    jQuery('#quick-action-chips').hide();

                    console.log('Clicked Chip:');
                    console.log(item);
                    setSearchAction({
                      route: item.route,
                      params: item.params,
                      results: item.results,
                    });
                  })}
                />
              </a>
            </div>
          ))}
        </div>
      )}
      {console.debug(`Rendered Feed ${++x} times`)}
      <Virtuoso
        ref={virtuoso}
        style={{ width: '100%', height: isMobile ? 'calc(100vh - 110px)' : 'calc(100vh - 64px)' }}
        // overscan={8000} // Overscan by 8k px to load ~15 cards at a time // FIXME This seems to slow the app down significantly
        overscan={2000} // INFO I think when this gets increased too much, it doesn't always call the next page
        data={posts}
        // defaultItemHeight={575}
        totalCount={posts.length + (hasMore ? 1 : 0)}
        // FIXME initialScrollTop seems to work but isn't always in the exact position it was in before
        //  Try https://gist.github.com/PaperPlane01/d8bc7fb9dcff06cc86fba220d0d9417d to use the index rather than offset
        // initialScrollTop={lastScrollOffset}
        itemContent={(index, post) => <Item post={post} />}
        endReached={() => fetchFeed(range.startIndex, range.stopIndex)}
        rangeChanged={({ startIndex, endIndex }) => { range = { startIndex, stopIndex: endIndex }; }}
        onScroll={(e) => {
          scrollOffset = e.target.scrollTop;
          // console.log('Current scroll offset:');
          // console.log(scrollOffset);
          const insetTop = safeAreaInsets.top;
          const top = 110 + insetTop;
          if (scrollOffset < 0) {
            jQuery('#quick-action-chips').css('top', top);
          } else if (scrollOffset < 56) {
            jQuery('#quick-action-chips').css('top', top - scrollOffset);
          } else if (lastScrollOffset < 56) {
            jQuery('#quick-action-chips').css('top', top - 56);
          } else {
            jQuery('#quick-action-chips').css('top', top - 56);
          }
          lastScrollOffset = e.target.scrollTop;
        }}
        components={{
          Header: () => <div style={{ height: 44 }} />,
          EmptyPlaceholder: () => <CardListSkeleton />,
          // FIXME For some reason, the height of the list is slightly off which makes the skeleton card at the bottom
          //  slightly overlap the last card at an inconsistent amount so adding an extra margin only works sometimes...
          Footer: () => <CardListSkeleton />,
        }}
      />
      {showHumidorEditor && renderHumidorEditor()}
      <Snackbar
        open={showSnackbar && window.location.pathname === '/'}
        autoHideDuration={60000}
        onClose={() => setShowSnackbar(false)}
        message="New posts available"
        ContentProps={{
          classes: {
            root: 'material-snackbar',
          },
        }}
        action={(
          <>
            <Button
              color="secondary"
              size="small"
              onClick={() => {
                FeedStore.update((s) => {
                  s.hasNewPosts = false;
                });
                setShowSnackbar(false);
                requestFeed(1);
                setTimeout(() => {
                  scrollToTop();
                }, 250);
              }}
            >
              REFRESH
            </Button>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => setShowSnackbar(false)}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </>
        )}
      />
    </div>
  );
}

FeedList.propTypes = {
  options: PropTypes.object,
  hasMore: PropTypes.bool,
};

const mapStateToProps = (state) => {
  const auth = state.get('auth').toJS();
  console.log('Auth:');
  console.log(auth);
  const { user } = auth;
  const currentUserId = user && user.id;
  console.log(`Getting humidors for ID: ${currentUserId}`);
  return {
    errorMessage: selectFeedErrorMessage()(state),
    hasMore: selectFeedHasMore()(state),
    auth: state.get('auth').toJS(),
    settings: state.get('settings'),
    humidors: currentUserId ? selectAllHumidors(currentUserId)(state) : [],
  };
};

function mapDispatchToProps(dispatch) {
  return {
    setSearchAction: (data) => dispatch(actionSetSearchAction(data)),
    updateSettings: (settings) => dispatch(actionUpdateSettings(settings)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(memo(FeedList)));
