import { fromJS, List } from 'immutable';
import { EXPLORE_CIGARS } from './actions';

const filterState = fromJS({
  currentPage: 0,
  totalPages: 999,
  loading: false,
  hasMore: true,
  errorMessage: '',
  cigars: List(),
});
export function filterCigarsReducer(state = filterState, action) {
  switch (action.type) {
    case EXPLORE_CIGARS.FETCH: {
      return state
        .set('loading', true)
        .set('currentPage', action.page)
        .set('errorMessage', '');
    }

    case EXPLORE_CIGARS.SUCCESS: {
      const { paging, cigars } = action;
      console.log('Cigars from reducer');
      console.log(cigars);
      console.log('Current paging:');
      console.log(paging);
      if (paging.currentPage === 1) {
        return state
          .set('totalPages', parseInt(paging.totalPages, 10))
          .set('loading', false)
          .set('hasMore', paging.hasMore)
          .set('cigars', List(cigars));
      } else {
        return state
          .set('totalPages', parseInt(paging.totalPages, 10))
          .set('loading', false)
          .update('cigars', (list) => list.push(...cigars));
      }
    }

    case EXPLORE_CIGARS.FAILURE: {
      return state
        .set('loading', false)
        .set('hasMore', false)
        .set('errorMessage', action.error.message);
    }

    default:
      return state;
  }
}

export default function cigarMappingReducer(state = filterState, action) {
  switch (action.type) {
    case EXPLORE_CIGARS.FETCH:
    case EXPLORE_CIGARS.SUCCESS:
    case EXPLORE_CIGARS.FAILURE: {
      const selectedList = state.get('explore_cigars');
      const updatedCigarState = filterCigarsReducer(selectedList, action);
      return state.set('explore_cigars', updatedCigarState);
    }

    default:
      return state;
  }
}
