import { firestore } from '../../../config/firebase.config';
import { FeedStore, UserStore } from '../../stores';
import Hashids from 'hashids';
const hashids = new Hashids('', 12);

const getPath = () => {
  const { user } = UserStore.getRawState();
  let path = 'feed/default/pages';
  // FIXME Add this back in once it's ready
  // if (user && user.id) {
  //   path = `feed/${hashids.encode(user.id)}/pages`;
  // }
  console.log(`Getting feed for path: ${path}`);
  return path;
};

export function requestFeed(page) {
  console.log('Getting feed from helper...');
  firestore.collection(getPath()).doc(`${page}`).get().then((doc) => {
    if (doc.exists) {
      const posts = JSON.parse(doc.data().items);
      console.log(`Feed snapshot for page ${page}`);
      console.log(posts);
      // FIXME This works, but once the ad is resolved, it needs to replace the posts with the ad content so it's not
      //  re-initializing every time the user scrolls by an ad placement - leaving it out until the next release
      // posts.splice((posts.length + 1) * Math.random() | 0, 0, { type: 'ad', zone_id: '103043' });
      // posts.splice((posts.length + 1) * Math.random() | 0, 0, { type: 'ad', zone_id: '103054' });
      FeedStore.update((s) => {
        s.currentPage = page;
        if (page === 1) {
          s.feed = posts;
        } else {
          s.feed.push(...posts);
        }
        window.localStorage.setItem('boxpressd-main-feed', JSON.stringify(s.feed));
      });
    } else {
      console.log(`Unable to get feed for page ${page}`);
    }
  }).catch((err) => {
    console.error('Feed error');
    console.error(err);
  });
}

export function watchFeed() {
  firestore.collection(getPath()).onSnapshot((snapshot) => {
    const newData = snapshot.docs;
    console.log('Feed snapshot for page `default`');
    console.log(newData);
    const currentFeed = FeedStore.getRawState().feed;
    newData.forEach((doc) => {
      const newFeed = JSON.parse(doc.data().items);
      // Take a look at the first page and compare to existing feed
      if (doc.id === '1') {
        // FIXME Also make sure the post is not from the current user
        if (currentFeed && currentFeed.length && newFeed && newFeed.length) {
          if (currentFeed[0].id !== newFeed[0].id) {
            console.log('Feed has changed!');
            // The feed is different
            FeedStore.update((s) => {
              s.hasNewPosts = true; // TODO As soon as the feed is updated based on the user interaction with the prompt, reset this
            });
          }
        }
      }
    });
  });
}

export function watchLikes() {

}

export function watchComments() {

}
