import { ActionTypes } from "./actions";

const initialState = {
    showEventModal: false
};

export default (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.SHOW_MODAL:
            return {
                showEventModal: action.showEventModal,
                type: action.type
            };
        case ActionTypes.HIDE_MODAL:
            return initialState;
        default:
            return state
    }
}
