import { createSelector } from 'reselect';
import { fromJS, List } from 'immutable';

export const selectFeedListMapping = () => (state) => state.getIn(['global', 'feed']);
export const selectFeedList = () => createSelector(
  selectFeedListMapping(),
  (feedState) => feedState.get('feed_items', fromJS({
    currentPage: 0,
    totalPages: 999,
    loading: false,
    errorMessage: '',
    posts: List(),
  }))
);

export const selectFeedLoading = () => createSelector(
  selectFeedList(),
  (feedState) => feedState.get('loading'),
);

export const selectFeedErrorMessage = () => createSelector(
  selectFeedList(),
  (feedState) => feedState.get('errorMessage'),
);

export const selectFeedCurrentPage = () => createSelector(
  selectFeedList(),
  (feedState) => feedState.get('currentPage'),
);

export const selectFeedTotalPage = () => createSelector(
  selectFeedList(),
  (feedState) => feedState.get('totalPages'),
);

export const selectFeedHasMore = () => createSelector(
  selectFeedCurrentPage(),
  selectFeedTotalPage(),
  (currentPage, totalPage) => (currentPage < totalPage),
);

export const selectFeedListEntities = () => createSelector(
  selectFeedList(),
  (feedState) => {
    // console.log('Feed state:');
    // console.log(feedState);
    // FIXME For now, this doesn't work very well in production since it drops all WP posts at the bottom and
    //  smoke sessions in the middle - I think we can just randomly insert the articles in the mix until this is
    //  handled on the server and we have more articles coming out daily
    return feedState.get('posts');
    //   .sort((a, b) => {
    //   let timestamp1;
    //   let timestamp2;
    //   if (a.type === 'session') {
    //     // 2019-11-21 01:54:56
    //     timestamp1 = Date.parse(a.session.timestamp.replace(' ', 'T'));
    //   } else {
    //     // 2019-02-11T18:30:07
    //     timestamp1 = Date.parse(a.date);
    //   }
    //   if (b.type === 'session') {
    //     timestamp2 = Date.parse(b.session.timestamp.replace(' ', 'T'));
    //   } else {
    //     timestamp2 = Date.parse(b.date);
    //   }
    //   return timestamp2 - timestamp1;
    // });
  },
);
