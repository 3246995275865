import { put, select, throttle } from 'redux-saga/effects';
import axios from 'axios';
import { LOAD_FEED, loadFeed, loadFeedSuccess, loadFeedFail } from './actions';
import { selectFeedLoading } from './selectors';

const env = process.env.NODE_ENV || 'development';
const config = require('../../../config/config.json')[env];

/**
 * @deprecated We're now using Firestore directly for the feed and realtime updates
 * @param action
 * @returns {Generator<SimpleEffect<"PUT", PutEffectDescriptor<{paging: *, page: *, type: *, posts: *}>>|SimpleEffect<"PUT", PutEffectDescriptor<{type: *, error: *}>>|SimpleEffect<"PUT", PutEffectDescriptor<{limit: number, page: *, type: *}>>|SimpleEffect<"SELECT", SelectEffectDescriptor>|Promise<AxiosResponse<any>>, void, *>}
 */
export function* loadFeedRequest(action) {
  const { page, limit } = action;

  console.log(`Checking status for feed page ${page}`);

  const isLoading = yield select(selectFeedLoading());
  if (isLoading) {
    return;
  }

  yield put(loadFeed(page));
  try {
    console.log(`Getting feed page ${page}`);
    let sessionResponse;
    // FIXME Once ready, turn precompute back on
    // const { user } = UserStore.getRawState();
    // if (user && user.id) {
    //   sessionResponse = yield axios.get(`${config.apiEndPoint}/users/${user.id}/feed?page=${page}&max=${limit}`);
    // } else {
      sessionResponse = yield axios.get(`${config.apiEndPoint}/feed?page=${page}&max=${limit}`);
    // }
    console.log(sessionResponse);
    const posts = sessionResponse.data;

    const pagingInformation = { totalPages: 999 }; // FIXME The server doesn't return paging info, but we'll never reach the end, we can phase this out
    yield put(loadFeedSuccess(page, pagingInformation, posts));
  } catch (error) {
    yield put(loadFeedFail(error));
  }
}

export function* loadFeedWatcher() {
  yield throttle(500, LOAD_FEED.REQUEST, loadFeedRequest);
}

export default [
  loadFeedWatcher,
];
