import { createAsyncAction } from '../../utils/actionUtils';

export const EXPLORE_CIGARS = createAsyncAction('EXPLORE_CIGARS');

export function requestCigars(page, options = {}) {
  console.log('Explorer request options');
  console.log(page);
  console.log(options);
  return {
    type: EXPLORE_CIGARS.REQUEST,
    page,
    options,
  };
}

export function exploreCigars(page, options) {
  return {
    type: EXPLORE_CIGARS.FETCH,
    page,
    options,
  };
}

export function exploreCigarsSuccess(paging, cigars) {
  return {
    type: EXPLORE_CIGARS.SUCCESS,
    paging,
    cigars,
  };
}

export function exploreCigarsError(error) {
  return {
    type: EXPLORE_CIGARS.FAILURE,
    error,
  };
}
