export const ActionTypes = {
    HIDE_MODAL: 'HIDE_EVENT_MODAL',
    SHOW_MODAL: 'SHOW_EVENT_MODAL',
};

export const showModal = () => ({
    type: ActionTypes.SHOW_MODAL,
    showEventModal: true
});

export const hideModal = () => ({
    type: ActionTypes.HIDE_MODAL,
    showEventModal: false
});
